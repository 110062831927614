

.main-header {
    background-color: #f5f5f5;
    padding: 1em;
    text-align: center;
  }
  
  .nav-links {
    list-style: none;
    padding: 0;
  }
  
  .nav-links li {
    display: inline;
    margin-right: 20px;
  }
  
  .nav-links a {
    text-decoration: none;
    color: #333;
  }
  
  .nav-links a:hover {
    text-decoration: underline;
  }
  