/* src/components/About/About.css */

.about-section {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.about-section h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.about-content h3 {
  color: #444;
  margin-top: 20px;
  margin-bottom: 10px;
}

.about-content p,
.about-content ul {
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;
}

.about-content ul {
  list-style-type: disc;
  padding-left: 20px;
}

.about-content ul li {
  margin-bottom: 10px;
}

.about-content a {
  color: #007BFF;
  text-decoration: none;
}

.about-content a:hover {
  text-decoration: underline;
}
