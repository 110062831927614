/* Global Styles */
body {
  font-family: 'Arial', sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
  line-height: 1.6;
}

/* Service Form */
.service-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

.service-form-title {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 48px;
  color: #333;
  text-align: center;
  font-family: 'Poppins', sans-serif; /* Exemple de police attrayante */
}

/* Package Container */
.package-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  margin-top: 3rem;
}

/* Package Card */
.package-card {
  border: 1px solid #32CD32;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.package-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Package Title */
.package-title {
  font-size: 28px; /* Augmentez cette valeur pour mettre en avant le titre */
  font-weight: bold;
  color: #007bff;
  margin-bottom: 10px;
}

/* Package Price */
.package-price {
  font-size: 24px;
  color: #333;
  margin: 10px 0;
  font-weight: bold;
}

/* Feature List */
.feature-list {
  list-style: none;
  padding: 0;
  text-align: left;
  margin: 20px 0;
}

.feature-item {
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
  color: #555;
  font-weight: bold; /* Texte en gras */
}

.feature-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjA1IDcuNTVMOS4yNSAxMi40Nkw3LjUgMTAuNzVMMy43NSA0LjYxMUw1LjE1NiAzLjI1TDEwLjc1IDExLjM4TDExLjA2IDExLjc4TDExLjM4IDExLjM4TDExLjc4IDExLjA2TDE0Ljg1IDcuNTVIMTQuMDVaIiBmaWxsPSIjMDBiMDAwIi8+Cjwvc3ZnPgo=') no-repeat center center;
  background-size: contain;
}

/* Select Button */
.select-button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s, transform 0.3s;
  text-align: center; /* Ajoute ceci pour centrer le texte */
  display: inline-block; /* Ajoute ceci pour permettre le centrage du texte */
}

.select-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .package-container {
      flex-direction: column;
      align-items: center;
  }

  .package-card {
      width: 90%;
      margin-bottom: 20px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .package-card {
      width: 45%;
  }
}
