/* src/components/5.ContactForm/ContactForm.css */

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem; /* 20px */
  box-sizing: border-box;
  min-height: 100vh;
  justify-content: flex-start; /* Assurez-vous que le contenu commence en haut */
  font-family: 'Arial', sans-serif; /* Utilise la police Arial pour la cohérence */
}

.contact-form h2 {
  margin-top: 1.25rem; /* 20px */
  margin-bottom: 1.25rem; /* 20px */
  font-size: 2.5rem; /* 40px */
  color: #007bff; /* Utilise la même couleur bleue que le bouton pour la cohérence */
  text-align: center;
  font-weight: bold; /* Met le titre en gras */
}

.contact-form .icons {
  font-size: 3rem; /* Taille des icônes */
  margin-bottom: 1.25rem; /* 20px */
  text-align: center;
}

.form-container {
  margin-top: 50px; /* Ajuste cette valeur pour l'espacement supérieur */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-form .form-group {
  margin-bottom: 0.9375rem; /* 15px */
  width: 100%;
  max-width: 31.25rem; /* 500px */
  text-align: left; /* Aligne les étiquettes à gauche */
}

.contact-form label {
  display: block;
  margin-bottom: 0.3125rem; /* 5px */
  font-weight: bold; /* Met les étiquettes en gras */
  color: #333; /* Couleur sombre pour les étiquettes */
}

.contact-form .required {
  color: red;
  font-weight: bold;
}

.contact-form .optional {
  font-weight: normal;
  color: #666;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 0.9375rem; /* 15px */
  border: 1px solid #ccc;
  border-radius: 0.25rem; /* 4px */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Ajoute une légère ombre interne */
  font-size: 1.25rem; /* Augmente légèrement la taille de la police pour la lisibilité */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Ajoute une transition pour les interactions */
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #007bff; /* Change la bordure en bleu lorsqu'on clique */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Ajoute une ombre bleue lors du focus */
  outline: none; /* Supprime l'outline par défaut */
}

.contact-form button {
  padding: 0.9375rem 1.875rem; /* 15px 30px */
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 0.25rem; /* 4px */
  cursor: pointer;
  font-size: 1.25rem; /* 20px */
  margin-top: 50px; /* Ajuste cette valeur pour l'espacement supérieur */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Ajoute une transition pour les interactions */
  display: inline-block;
  text-align: center;
  transform: translateX(-50%);
  position: relative;
  left: 50%;
}

.contact-form button:hover {
  background-color: #0056b3;
  transform: translate(-50%, -2px); /* Déplace légèrement le bouton vers le haut au survol */
}

.contact-form p {
  margin-top: 20px;
  font-size: 1rem;
  color: #333;
  text-align: center;
}
