.profile-form {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: flex-start; 
    height: 100vh;
    margin: 0;
    padding: 20px;
    font-family: 'Roboto', sans-serif; 
    background-color: #fff; 
}

@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

.profile-form h1 {
    margin-top: 20px;
    font-size: 4rem;
    font-weight: 700;
    color: grey;
    font-family: 'Lobster', cursive;
}

.emoji-large {
    font-size: 8rem; 
    margin: 10px 0; 
}

.profile-form select {
    width: 60%;
    padding: 12px;
    border-radius: 6px;
    font-size: 1.3rem;
    margin-top: 20px; 
    text-align: center; 
    text-align-last: center; 
    font-weight: bold; 
    background-color: #fff; 
    color: #333; 
}

.custom-button {
    margin-top: 30px; 
    padding: 1em 2.5em;
    font-size: 1.2em;
    color: white;
    background-color: #007bff; 
    border: none;
    cursor: pointer;
    border-radius: 8px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    transition: all 0.3s ease;
    font-family: 'Roboto', sans-serif; 
}

.custom-button:hover {
    background-color: #0056b3;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); 
}
