.problem-solution-fit {
    margin-top: 0px; 
    padding: 30px;
    max-width: 800px; 
    text-align: left;
    transition: all 0.3s ease;
    font-family: 'Roboto', sans-serif; 
    color: #333; 
    line-height: 1.6; 
    border: none; 
}

.problem-solution-fit h2 {
    font-size: 2rem; 
    margin-bottom: 15px;
    color: #007bff;
    font-weight: 700; 
}

.problem-solution-fit p {
    font-size: 1.2rem; 
    font-weight: bold; 
    margin-bottom: 20px; 
}

.problem-solution-fit ul {
    list-style-type: none;
    padding: 0;
    margin: 0 0 20px 0;
}

.problem-solution-fit ul li {
    font-size: 1.2rem;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    color: #333; 
}

.problem-solution-fit ul li::before {
    content: "✓";
    font-size: 1.5rem;
    color: #28a745;
    margin-right: 10px;
}

.problem-solution-fit .cta-button {
    display: block;
    margin: 4rem auto 0; 
    padding: 1.2rem 3rem; 
    font-size: 1.2rem;
    color: #fff;
    background: #007bff;
    border-radius: 8px; 
    text-decoration: none;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
    transition: all 0.3s ease;
}

.problem-solution-fit .cta-button:hover {
    background-color: #0056b3;
}
