.homePage {
  position: relative;
  text-align: center; /* Centre le contenu */
  height: 100vh; /* Utilise la hauteur complète de la fenêtre */
}

.background-image {
  width: 98%;  /* Réduit la largeur à 95% de la largeur du conteneur */
  height: 98%; /* Réduit la hauteur à 95% de la hauteur du conteneur */
  object-fit: contain; /* Utilise 'contain' pour garder tout le contenu visible */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centre l'image exactement */
  z-index: -1;
}

.background-image2  {
  width: 98%;  /* Réduit la largeur à 95% de la largeur du conteneur */
  height: 98%; /* Réduit la hauteur à 95% de la hauteur du conteneur */
  object-fit: contain; /* Utilise 'contain' pour garder tout le contenu visible */
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centre l'image exactement */
  z-index: -1;
}

.background-image3 {
  width: 95%;  /* Réduit la largeur à 95% de la largeur du conteneur */
  height: 95%; /* Réduit la hauteur à 95% de la hauteur du conteneur */
  object-fit: contain; /* Utilise 'contain' pour garder tout le contenu visible */
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centre l'image exactement */
  z-index: -1;
}

.cta {
  position: absolute;
  bottom: 260px; /* Ajustez cette valeur en fonction de la fin de votre image */
  right: 19rem; /* Assurez-vous que cela place le bouton où vous le souhaitez horizontalement */
  padding: 1rem 2rem;
  font-size: 1.2rem;
  color: white;
  background-color: red;
  border: none;
  cursor: pointer;
  z-index: 10;
}


/* Pour les tablettes */
@media (max-width: 768px) {
  .cta {
    bottom: 12.5rem; /* 200px / 16 */
    right: 3.125rem; /* 50px / 16 */
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
}

/* Pour les téléphones mobiles */
@media (max-width: 480px) {
  .cta {
    position: fixed; /* change à fixed pour éviter les problèmes de défilement */
    bottom: 6.25rem; /* 100px / 16 */
    right: 1.25rem; /* 20px / 16 */
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
  }
}


.cta2
{
  position: absolute;
  bottom: 550px; /* Ajusté de 50px à 30px pour remonter le bouton */
  right: 200px;
  padding: 1em 2em;
  font-size: 1.2em;
  color: white;
  background-color: red;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.cta3
{
  position: absolute;
  bottom: 300px; /* Ajusté de 50px à 30px pour remonter le bouton */
  right: 120px;
  padding: 1em 2em;
  font-size: 1.2em;
  color: white;
  background-color: red;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.cta:hover {
  background-color: #0056b3;
}

.cta2:hover:hover {
  background-color: green;
}

.cta3:hover:hover {
  background-color: green;
}



